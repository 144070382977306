import { useEffect, useState } from "react";

export default function Hexagono(props) {
    let { size = 10, x = 0, y = 0, estado, setEstado } = props;
    const [dropShadow, setDropShadow] = useState((Math.floor(Math.random() * 7) + 3));
    const [firstEffectDone, setFirstEffectDone] = useState(false);
    const [hacerEfecto, setHacerEfecto] = useState();

    useEffect(() => {
        if (dropShadow > 1) {
            const timer = setTimeout(() => {
                if (dropShadow - 1 === 1) {
                    setHacerEfecto(null);
                }

                setDropShadow(dropShadow - 1);
            }, 100);

            return () => clearTimeout(timer);
        } else {
            setFirstEffectDone(true);
        }
    }, [dropShadow]);

    useEffect(() => {
        if (firstEffectDone) {
            const timer = setInterval(() => {
                if (dropShadow === 1 && !hacerEfecto) {
                    setDropShadow(6);
                }
            }, Math.random() * 100000);

            return () => clearInterval(timer);
        }
    }, [firstEffectDone, dropShadow]);

    useEffect(() => {
        if (hacerEfecto) {
            setDropShadow(Math.floor(Math.random() * 7) + 3);
        }
    }, [hacerEfecto]);

    useEffect(() => {
        if (estado) {
            setHacerEfecto(estado);
        }
    }, [estado]);

    let points = `${size * 2},${size / 2} ${size * 3},${size} ${size * 3},${size * 2} ${size * 2},${size * 2.5} ${size},${size * 2} ${size},${size}`;

    return (
        <div style={{ position: "relative" }}>
            <svg xmlns="http://www.w3.org/2000/svg"
                style={{
                    filter: `drop-shadow(0 0 ${dropShadow}px ${hacerEfecto ? hacerEfecto : "#71F0EC"})`,
                    overflow: "visible",
                    position: "absolute",
                    background: "transparent",
                    left: x,
                    top: y,
                }}>
                <polygon points={points} fill="#141414" stroke={
                    dropShadow > 1 ? (hacerEfecto ? hacerEfecto : "#71F0EC") : "#141414"
                } strokeWidth="1"
                />
            </svg>
            <div
                onMouseEnter={() => { setDropShadow(10) }}
                style={{
                    width: size * 2,
                    height: size * 2,
                    position: "absolute",
                    background: "transparent",
                    left: x + size * 2,
                    top: y + size + size / 2,
                    zIndex: 9999,
                    transform: "translate(-50%, -50%)",
                }} />
        </div>
    );
}