import Malla from "../../components/Malla";
import { Box, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useFetch from "../../utils/Fetch";
import InputAdornment from '@mui/material/InputAdornment';

export default function Formulario() {
    const [estadoFormulario, setEstadoFormulario] = useState(null);
    const [nombre, setNombre] = useState("");
    const [telefono, setTelefono] = useState("");
    const [instagram, setInstagram] = useState("");
    const [paraQue, setParaQue] = useState("");

    const { postFetch } = useFetch();

    useEffect(() => {
        if (estadoFormulario) {
            setEstadoFormulario(null);
        }
    }, [estadoFormulario]);

    const textFieldStyle = {
        color: "white",
        width: "80%",
        fontWeight: "bold",
        "& textarea": {
            scrollbarWidth: "none", // Para Firefox
            "&::-webkit-scrollbar": {
                display: "none", // Para Chrome y Safari
            },
        },

        "& label": {
            color: "#E5E5E5",
            fontFamily: "Courier New",
            fontWeight: "bold",
            fontSize: 14,
        },

        "& label.Mui-focused": {
            color: "#E5E5E5",
        },

        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#E5E5E5",
                borderRadius: 2,
                fontFamily: "Courier New",
            },
            "&:hover fieldset": {
                borderColor: "#71F0EC",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#71F0EC",
            },
        },

        "& .MuiInputBase-input": {
            color: "white",
            fontFamily: "Courier New",
        },

        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#E5E5E5",
        },
    };

    function handlePostFormulario() {
        if (!nombre || !telefono || !instagram) {
            setEstadoFormulario("#f07171");
            return;
        }

        postFetch("form/formulario/", {
            nombre: nombre,
            telefono: telefono,
            instagram: instagram,
            paraQue: paraQue === "" ? "No especificado" : paraQue,
        })
            .then((response) => {
                setEstadoFormulario("#75f071");
                setNombre("");
                setTelefono("");
                setInstagram("");
                setParaQue("");

                // esperrar 1000 segundos y redirigir a instagram
                setTimeout(() => {
                    window.location.href = "https://www.instagram.com/cave_soft?igsh=bms0OWh4eWV2Y3Fu";
                }, 1000);
            })
            .catch((error) => {
                alert(error)
                setEstadoFormulario("#f07171");
            });
    }

    return <div>
        <Malla estadoFormulario={estadoFormulario} setEstadoFormulario={setEstadoFormulario} />
        <Box
            sx={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                background: "rgba(0,0,0,0.7)",
                color: "#E5E5E5",
                width: { xs: "90%", md: "40%" },
                paddingBottom: 3,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                borderRadius: 6,
            }}
        >
            <Typography variant="h3" sx={{
                fontFamily: "Orbitron",
                paddingY: 2,
                fontWeight: "bold",
            }}>
                Cave
            </Typography>

            <Typography sx={{
                width: "80%",
                color: "#E5E5E5",
                marginBottom: 2,
                fontFamily: "Courier New",
                fontWeight: "bold",
            }}>
                Para participar del sorteo por una landing page, completá el siguiente formulario.
            </Typography>
            <TextField
                label="Nombre Completo"
                variant="outlined"
                margin="normal"
                sx={textFieldStyle}
                value={nombre}
                onChange={(event) => {
                    if (event.target.value.length > 70) {
                        return;
                    } else setNombre(event.target.value)
                }}
            />
            <TextField
                label="Teléfono"
                variant="outlined"
                margin="normal"
                sx={textFieldStyle}
                value={telefono}
                onChange={(event) => {
                    if (isNaN(event.target.value)) {
                        return;
                    } else if (event.target.value.length > 15) {
                        return;
                    } else setTelefono(event.target.value);
                }}
            />
            <TextField
                label="Instagram"
                variant="outlined"
                margin="normal"
                sx={textFieldStyle}
                value={instagram}
                onChange={(event) => {
                    if (event.target.value.length > 70) {
                        return;
                    } else setInstagram(event.target.value)
                }}
            />
            <TextField
                label="¿Para qué necesitas un sistema?"
                variant="outlined"
                margin="normal"
                sx={textFieldStyle}
                rows={2}
                multiline
                value={paraQue}
                onChange={(event) => {
                    if (event.target.value.length > 200) {
                        return;
                    } else setParaQue(event.target.value)
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end"
                            sx={{
                                color: "#E5E5E5",
                                fontFamily: "Courier New",
                                fontWeight: "bold",
                                fontSize: 12,
                            }}>
                            {200 - paraQue.length}
                        </InputAdornment>
                    ),
                }}
            />
            <Box
                onClick={() => {
                    handlePostFormulario();
                }}
                sx={{
                    width: "80%",
                    borderRadius: 4,
                    border: "2px solid #E5E5E5",
                    paddingY: 2,
                    marginTop: 2,
                    cursor: "pointer",
                    fontFamily: "ignis et glacies sharp",

                    "&:active": {
                        background: "#E5E5E5",
                        color: "#141414",
                    },

                    "&:hover": {
                        background: { md: "#E5E5E5" },
                        color: { md: "#141414" },
                    },
                }}>
                ENVIAR
            </Box>
        </Box>
    </div >
}