import Hexagono from "./Hexagono";

export default function Malla({ estadoFormulario, setEstadoFormulario }) {
    function getPuntos() {
        let lineas = []

        let anchoPantalla = window.innerWidth;

        let size = 28;
        let altoPoligono = size * Math.sqrt(3);

        let anchoPoligono = altoPoligono * Math.sqrt(3);

        let cantidadPorFila = Math.floor(anchoPantalla / anchoPoligono) + 2;
        let altoPantalla = window.innerHeight;
        let cantidadPorColumna = Math.floor(altoPantalla / altoPoligono);

        for (let i = 0; i < cantidadPorFila; i++) {
            for (let j = 0; j < cantidadPorColumna - 1; j++) {
                let x = anchoPoligono * i * 1
                let y = altoPoligono * j * 1.3

                if (j % 2 === 0) {
                    x = x + anchoPoligono / 2;
                }

                lineas.push({ x, y });
            }
        }

        return lineas
    }

    return <div style={{
        width: "100%",
        height: "100%",
        position: "fixed",
        left: -100,
        top: -100,
    }}>
        {/* <div style={{
            width: "100%",
            height: "80px",
            background: "red",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1,
            border: "1px solid #71F0EC",
            background: "linear-gradient(to bottom, rgba(7, 7, 7, 1) 30%, rgba(7, 7, 7, 0.5) 50%, rgba(7, 7, 7, 0.2) 80%, rgba(7, 7, 7, 0.1) 90%, rgba(7, 7, 7, 0) 100%)",

        }}></div> */}

        {getPuntos().map((punto, index) => {
            return <Hexagono
                key={index}
                x={punto.x}
                y={punto.y}
                size={31}
                estado={estadoFormulario}
                setEstado={setEstadoFormulario}
            />
        })}
    </div >
}